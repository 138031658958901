import logo from "../images/fmb_logo.svg";
import { userSchema } from "../components/Validation/EmailVerifySchema";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useState } from "react";
import InputField from "../components/InputField";
import SignInButton from "../components/SignInButton";

const EmailVerify = () => {
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  // const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  let navigate = useNavigate();

  const HandleForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/register`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          // authorization: "your token comes here",
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setError(res.data.data);
          //  if(res.data.status === true) {

          //  }
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Sent Email Successfully",
        error: "A Problem Has Occurred",
      });
    }
  };

  return (
    <div className="h-screen w-full bg-white overflow-auto">
      <div className="flex flex-col items-center text-center gap-5 justify-start md:mt-18 p-2 mt-8 2xl:mt-12 ">
        <img className="h-36" src={logo} alt="" />

        <div>
          <h1 className="text-3xl font-bold">Sign Up</h1>
        </div>

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="w-3/4  lg:w-1/5 flex flex-col justify-center gap-3"
          >
            <div className="flex flex-col text-left gap-2">
              <InputField
                name={"email"}
                type="email"
                placeholder="Enter your email"
              />
            </div>

            <p className="text-center text-red-700 mt-2  mb-2 w-full">
              {error}
            </p>

            {/* <div className="error-hndl">
            <p className=" mt-4 error-msg">{errors.email?.message}</p>
            <p className=" mt-4 error-msg">{errors.password?.message}</p>
            <p className="error-msg">{error}</p>
          </div> */}

            <SignInButton>Send</SignInButton>
          </form>
        </FormProvider>

        <div>
          <h3 className="text-base ">Already have an account?</h3>
          <Link
            to={"/login"}
            className="text-primary underline text-base"
            href=""
          >
            Sign in
          </Link>
        </div>

        <Link to={"/forgot"} className=" text-primary text-sm">
          Forgot Password?
        </Link>
      </div>
    </div>
  );
};

export default EmailVerify;
