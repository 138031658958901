import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { userSchema } from "../components/Validation/ResetPsSchema";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import InputField from "../components/InputField";
import SignInButtonBig from "../components/SignInButtonBig";
import PermModal from "../components/PermModal";

const ResetPassword = () => {
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  // const history = useHistory();
  const [permModal, setPermModal] = useState();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  let navigate = useNavigate();

  const params = useParams();

  //For verifying user
  useEffect(() => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/resetpassword?token=${params.id}`,
      method: "GET",
      headers: {
        // Add any auth token here
        "Content-Type": "application/json",
        // authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
      })
      // Catch errors if any
      .catch((err) => {
        setPermModal(true);
        return Promise.reject();
      });
    toast.promise(promise, {
      loading: "Loading",
      success: "Valid Token",
      error: "Invalid Token",
    });
  }, []);

  const HandleForm = (data) => {
    // console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      // delete data["passwordConfirmation"];
      console.log(data);
      data["token"] = params.id;
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/new-password`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          // authorization: "your token comes here",
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          // setError(res.message);

          //redirecting
          navigate("/login");
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          setError(err.response.data.error);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Password Reset Successfully",
        error: "An error has occurred",
      });
    }
  };

  return (
    <div className="h-screen w-full bg-white overflow-auto ">
      <div className="flex flex-col items-center text-center gap-5 justify-start md:mt-20 2xl:mt-72 p-8">
        <div>
          <h1 className="text-3xl font-bold">Reset Your Password</h1>
          <h3 className="text-base mt-2">Enter your new password</h3>
        </div>

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="w-3/4  lg:w-1/5 flex flex-col justify-center gap-3"
          >
            <InputField
              type="password"
              placeholder="Enter Your New Password"
              name="password"
            />

            <InputField
              type="password"
              placeholder="Confirm  Your New Password"
              name="password_confirmation"
            />

            <div className="mt-3">
              <p className="text-red-700 text-center w-full">{error}</p>
            </div>

            <SignInButtonBig>Confirm</SignInButtonBig>
          </form>
        </FormProvider>
      </div>
      {/* Redirecting Modal */}
      <PermModal show={permModal} className="md:w-4/5 lg:w-2/6">
        <div className="flex flex-col items-center text-center gap-2">
          <h1 className="text-2xl font-bold text-red-700">Invalid Token</h1>
          <p className="mt-2 w-4/5  text-base">
            Please check your email or send a new request through the button
            below.
          </p>
          <Link
            className="p-4 mt-3 bg-primary text-white  primary-hover rounded-xl"
            to="/forgot"
          >
            <h3>Reset Password</h3>
          </Link>
        </div>
      </PermModal>
    </div>
  );
};

export default ResetPassword;
