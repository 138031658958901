//STYLES
import styles from "./Navbar.module.scss";

//CONTEXT
import { useContext, useState } from "react";
import NavContext from "../../Context/NavContext";

//REACT ROUTER
import { NavLink, useLocation, useNavigate } from "react-router-dom";

//ICONS
import {
  MdOutlineDashboard,
  MdOutlineStore,
  MdBusiness,
  MdContacts,
  MdRateReview,
} from "react-icons/md";

//LOGOS
import logo from "../../images/fmb_text_logo.svg";
import love from "../../images/made-with-love.svg";

import { IoMdLogIn } from "react-icons/io";
import { FaReact, FaTimes } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { VscDashboard } from "react-icons/vsc";

const NavUrl = ({ url, icon, description, dropdownItems }) => {
  const { dropdownStatus, setDropdownStatus } = useState();
  const { nav, setNav } = useContext(NavContext);
  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  const useCurrentPath = () => {
    const location = useLocation();
    let currentPath = location.pathname;
    currentPath = currentPath.substring(1);
    return currentPath;
  };

  return (
    <li className={`${styles.li_navlink}`}>
      <NavLink
        to={`${url}`}
        className={({ isActive }) => (isActive ? styles.active : undefined)}
        onClick={() => {
          checkWindowSize();
        }}
      >
        {icon}
        <span className={`${styles.li_navlink} whitespace-nowrap p-2 mx-2`}>
          {description}
        </span>
      </NavLink>

      <div
        className={
          useCurrentPath().indexOf(url) > -1 ? `${styles.dropdown}` : "hidden"
        }
      >
        <div className={dropdownItems ? "pb-4" : "hidden"}>
          {dropdownItems
            ? dropdownItems.map((dropdownItems) => (
                <li className={`${styles.dropdown}`}>
                  <NavLink
                    to={`${dropdownItems.url}`}
                    className={({ isActive }) =>
                      isActive ? styles.active : undefined
                    }
                  >
                    {" "}
                    {dropdownItems.icon}
                    <span className={`whitespace-nowrap p-2 mx-2`}>
                      {dropdownItems.description}
                    </span>
                  </NavLink>
                </li>
              ))
            : ""}
        </div>
      </div>
    </li>
  );
};

const Navbar = () => {
  const { nav, setNav } = useContext(NavContext);
  let navigate = useNavigate();
  return (
    <div
      className={`${styles.navbar_container}  ${
        nav ? styles.navbar_mobile_active : undefined
      }`}
    >
      <nav className={nav ? undefined : styles.nav_small}>
        {/* LOGO */}
        <div className={styles.logo}>
          <img className="h-24 m-auto" src={logo} alt="" />
          <FaTimes
            className={styles.mobile_cancel_icon}
            onClick={() => {
              setNav(!nav);
            }}
          />
        </div>

        {/* MENU */}
        <ul className={`${styles.menu_container}`}>
          {/* FIRST CATEGORY */}
          {/* <span className={styles.categories}>
            {nav ? "Pages" : <BsThreeDots />}
          </span> */}

          <NavUrl
            url="SU/dashboard"
            icon={<MdOutlineDashboard />}
            description="Dashboard"
          />

          <NavUrl
            url="SU/industries"
            icon={<MdBusiness />}
            description="Industries"
          />

          <NavUrl
            url="SU/user-req"
            icon={<MdContacts />}
            description="User Requests"
          />

          <NavUrl
            url="SU/brands"
            icon={<MdOutlineStore />}
            description="Brands"
            dropdownItems={[
              {
                description: "Active Brands",
                url: "SU/brands/active-brands",
                // icon: <MdContacts />,
              },
              {
                description: "Inactive Brands",
                url: "SU/brands/inactive-brands",
                // icon: <MdRequestPage />,
              },
              // {
              //   description: "Brand Requests",
              //   url: "SU/brands/brand-requests",
              //   // icon: <MdRequestPage />,
              // },
            ]}
          />

          <NavUrl
            url="SU/feedback"
            icon={<MdRateReview />}
            description="Feedback"
            dropdownItems={[
              {
                description: "Feedback Systems",
                url: "SU/feedback/feedback-systems",
                // icon: <MdContacts />,
              },
            ]}
          />

          {/* SECOND CATEGORY */}
          {/* <span className={`${styles.categories} ${styles.second_category}`}>
            {nav ? "More" : <BsThreeDots />}
          </span> */}

          {/* <NavUrl
            url="other1"
            icon={<IoMdLogIn />}
            description="Authentication"
          />
          <NavUrl url="other2" icon={<FaReact />} description="ReactJs" /> */}
        </ul>

        {/* MADE WITH LOVE */}
        <div className={styles.made_love}>
          <img src={love} alt="" />
        </div>
      </nav>

      <div
        className={nav ? styles.mobile_nav_background_active : undefined}
        onClick={() => {
          setNav(!nav);
        }}
      ></div>
    </div>
  );
};

export default Navbar;
