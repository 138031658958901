const SignInButtonBig = ({ children }) => {
  return (
    <button
      type="submit"
      className="mt-4 px-6 py-4 bg-primary rounded-2xl text-white w-full md:w-2/5 lg:w-3/5  self-center text-base primary-hover"
    >
      {children}
    </button>
  );
};

export default SignInButtonBig;
