import triangle from "../../images/dashboard-img.svg";
import CountUp from "react-countup";

const Cards = (props) => {
  return (
    <div
      className={`p-5 w-2/6 rounded-2xl max-w-xs drop-shadow-xl relative text-white ${props.className}`}
    >
      <h2 className="text-2xl font-bold ">{props.label}</h2>
      <div className="flex  flex-row  justify-between items-end mt-2">
        <h1 className="text-4xl font-bold">
          <CountUp end={props.number} duration={2} useEasing={true} />{" "}
        </h1>
      </div>
      <img
        className="h-28 bottom-0 right-2 opacity-20 absolute -z-10"
        src={triangle}
        alt=""
      />
    </div>
  );
};

export default Cards;
