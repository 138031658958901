import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";

import { userSchema } from "../components/Validation/SignupSchema";
import logo from "../images/fmb_logo.svg";
import InputField from "../components/InputField";
import SignInButton from "../components/SignInButton";
import PermModal from "./../components/PermModal";
import SelectField from "../components/SelectField";
import DisplayNameWithID from "../components/DisplayNameWithID";
import FormSelectField from "../components/FormSelectField";

const SignUp = () => {
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  // const history = useHistory();

  const [disabled, setDisabled] = useState(false);
  const [userInfo, setUserInfo] = useState({ user_id: "" });
  const [permModal, setPermModal] = useState();
  const [industry, setIndustry] = useState([]);
  const [error, setError] = useState();
  let navigate = useNavigate();

  //For getting id sent through API
  const params = useParams();

  //For verifying user
  useEffect(() => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/signup?code=${params.id}`,
      method: "GET",
      headers: {
        // Add any auth token here
        "Content-Type": "application/json",
        // authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setUserInfo(res.data.data);

        console.log(userInfo);
      })
      // Catch errors if any
      .catch((err) => {
        setPermModal(true);
        return Promise.reject();
      });
    toast.promise(promise, {
      loading: "Loading",
      success: "Email Verified",
      error: "Email Not Verified",
    });
    fetchingIndustries();
  }, []);

  const fetchingIndustries = () => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/industry?&status=enable`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setIndustry(res.data.data);
      })

      // Catch errors if any
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });

    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "Office Added Successfully",
    //   error: "An error has occurred",
    // });
  };

  const errorDisplay = (error) => {
    if (error) {
      const answer = error.split(",").join("\n");
      return answer;
    }
  };

  const HandleForm = (data) => {
    data["user_id"] = userInfo.user_id;
    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/verification`,
        method: "POST",
        headers: {
          // Add any auth token here
          "Content-Type": "application/json",
          // authorization: "your token comes here",
        },
        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          navigate("/signup-wait");
        })
        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setError(err.response.data.error);
          console.log(err);
          // toast.error(err.response.data.error);
          return Promise.reject();
        });
      toast.promise(promise, {
        loading: "Loading",
        success: "Request Sent Successfully",
        error: "An error has occurred",
      });
    }
  };

  return (
    <div className="h-screen w-full bg-white overflow-auto ">
      <div className="flex flex-col items-center text-center gap-5 justify-start md:mt-18 p-2 mt-8 2xl:mt-12 ">
        <img className="h-36" src={logo} alt="" />

        <div>
          <h1 className="text-3xl font-bold">Sign Up</h1>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            action=""
            className="w-3/4  lg:w-1/5 flex flex-col justify-center gap-3"
          >
            <InputField name={"name"} placeholder="Name" />

            <InputField
              name={"brand_name"}
              type="text"
              placeholder="Brand Name"
            />
            <InputField
              name={"phone_no"}
              type="number"
              placeholder="Contact Number"
              min={0}
            />

            <SelectField name="industry" required>
              <option value="" disabled selected>
                Select Industry
              </option>

              {industry.map((industry) => (
                <DisplayNameWithID name={industry.name} id={industry.id} />
              ))}
            </SelectField>

            <p className="text-center text-red-700 mt-2 w-full">
              {errorDisplay(error)}
            </p>

            <SignInButton>Sign Up</SignInButton>
          </form>
        </FormProvider>

        <div>
          <h3 className="text-base ">Already have an account yet?</h3>
          <Link
            to={"/login"}
            className=" underline text-base text-primary"
            href=""
          >
            Sign In
          </Link>
        </div>
      </div>

      {/* Redirecting Modal */}
      <PermModal show={permModal} className="md:w-4/5 lg:w-2/6">
        <div className="flex flex-col items-center text-center gap-2">
          <h1 className="text-2xl text-red-700">Email Not Verified</h1>
          <p className="mt-2 w-4/5 text-base">
            Your request has not been verified. Please check your email or send
            a new request through the button below.
          </p>
          <Link
            className="p-4 mt-3 bg-primary text-white  primary-hover rounded-xl"
            to="/signup"
          >
            <h3>Verify Email</h3>
          </Link>
        </div>
      </PermModal>
    </div>
  );
};

export default SignUp;
