import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import profile from "../../../images/table/default_profile.svg";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import editIcon from "../../../images/table/edit.svg";
import deleteIcon from "../../../images/table/delete.svg";
import reactivateIcon from "../../../images/table/reactivate.svg";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../../../components/Validation/SuperAdmin/AddSystemSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import { formatDate } from "../../../components/Functions";
import FormInputField from "../../../components/FormInputField";
import DisplayNameWithID from "../../../components/DisplayNameWithID";
import FormSelectField from "../../../components/FormSelectField";
import ErrorDisplay from "../../../components/ErrorDisplay";
import QuestionsTable from "../../../components/QuestionsTable";
import { useNavigate } from "react-router-dom";

const FeedbackSystems = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [industry, setIndustry] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    status: "",

    industry: {
      name: "",
      id: "",
    },
    id: "",
  });
  const navigate = useNavigate();
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  // const forceUpdate = React.useReducer((bool) => !bool)[1];

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);
    setEditModal(true);
  };

  const statusStyling = (status) => {
    if (status === "enable")
      return {
        background: "#55ac1c47",
        border: "1px solid #55AC1C",
        borderRadius: "8px",
        color: "#55AC1C",
        width: "5rem",
        padding: "3px",
        textAlign: "center",
      };

    if (status === "disable")
      return {
        background: "#C3222247",
        border: "1px solid #C32222",
        borderRadius: "8px",
        color: "#C32222",
        width: "5rem",
        padding: "3px",
        textAlign: "center",
      };
  };

  const addQuestion = () => {
    navigate("add-question", {
      state: {
        id: selectedUser.id,
        name: selectedUser.name,
      },
    });
  };

  //  fetching industries
  useEffect(() => {
    fetchingIndustries();
  }, []); // <-- empty dependency array

  const fetchingIndustries = () => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/industry?&status=enable`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setIndustry(res.data.data);
      })

      // Catch errors if any
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });

    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "Office Added Successfully",
    //   error: "An error has occurred",
    // });
  };

  //Adding user
  const HandleForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/system`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setModal(false);
          setDisabled(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "System Added Successfully",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      //   const newData = removeEmpty(data);
      //   console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(data).forEach((key) => formData.append(key, data[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/system/${selectedUser.id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setError(err.response.data.message);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Industry Updated Successfully",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);

    if (!disabled) {
      setDisabled(true);
      let data;
      if (selectedUser.status === "enable") {
        data = {
          status: "disable",
        };
      } else {
        data = {
          status: "enable",
        };
      }
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/industry-status/${selectedUser.id}`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated Successfully",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    { title: "NAME", field: "name", filtering: false },
    { title: "SYSTEM FOR", field: "industry.name", filtering: false },
    { title: "QUESTIONS", field: "questions_count", filtering: false },
    {
      title: "CREATED DATE",
      field: "created_at",
      filtering: false,
      render: (rowData) => formatDate(rowData.created_at),
    },

    // {
    //   title: "STATUS",
    //   field: "status",
    //   filtering: false,
    //   render: (rowData) => (
    //     <div style={statusStyling(rowData.status)}>{rowData.status}</div>
    //   ),
    // },

    // {
    //   title: "STATUS",
    //   field: "status",
    //   lookup: { Active: "Active", Inactive: "Inactive" },
    //   render: (rowData) => (
    //     <div
    //       style={
    //         rowData.status == "Active"
    //           ? {
    //               background: "#55ac1c47",
    //               border: "1px solid #55AC1C",
    //               borderRadius: "8px",
    //               color: "#55AC1C",
    //               width: "5rem",
    //               padding: "3px",
    //               textAlign: "center",
    //             }
    //           : {
    //               background: "#C3222247",
    //               color: "#C32222",
    //               border: "1px solid #C32222",
    //               borderRadius: "8px",
    //               width: "5rem",
    //               padding: "3px",
    //               textAlign: "center",
    //             }
    //       }
    //     >
    //       {rowData.status}
    //     </div>
    //   ),
    // },
  ];

  const QuestionColumns = [
    { title: "NAME", field: "name", filtering: false },
    { title: "DESCRIPTION", field: "description", filtering: false },
    { title: "SCREEN TITLE", field: "screen_title", filtering: false },
    {
      title: "CREATED DATE",
      field: "created_at",
      filtering: false,
      render: (rowData) => formatDate(rowData.created_at),
    },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <button
            onClick={() => setModal(true)}
            className="py-4 px-10 bg-primaryButton  hover:shadow hover:shadow-primaryButton text-white font-thin rounded-xl"
          >
            Add System
          </button>
        </div>
        <Table
          columns={columns}
          tableRef={tableRef}
          searchTerm="key"
          customUrl={`${process.env.REACT_APP_BASEURL}/api/system?`}
          actions={[
            {
              icon: EditIcon,
              onClick: (event, rowData) => {
                reset();
                editUser(rowData);
                setEditForm(false);
              },
            },
            // (rowData) => ({
            //   icon: rowData.status === "enable" ? DeleteIcon : RotateLeftIcon,

            //   onClick: (event, rowData) => {
            //     reset();
            //     deleteUser(rowData);
            //   },
            // }),
          ]}
        />
      </div>

      {/* Add Modal */}
      <Modal
        show={modal}
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center whitespace-nowrap"
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="text-lg mb-2 block font-semibold">
                  System Name
                </label>
                <FormInputField name={"name"} required />
              </div>

              <div>
                <label className="text-lg mb-2 block font-semibold">
                  System For
                </label>
                <FormSelectField name="industry_id" required>
                  <option value="" disabled selected>
                    Select Industry
                  </option>

                  {industry.map((industry) => (
                    <DisplayNameWithID name={industry.name} id={industry.id} />
                  ))}
                </FormSelectField>
              </div>

              <ErrorDisplay>{error}</ErrorDisplay>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="grey p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit Modal */}
      <Modal
        show={editModal}
        className="h-5/6 w-5/6"
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-3 justify-center align-center whitespace-nowrap"
          >
            <h3 className="text-2xl font-semibold mt-3">System Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="text-lg mb-2 block font-semibold">
                  System Name
                </label>
                <FormInputField
                  name={"name"}
                  disabled={!editForm}
                  placeholder={selectedUser.name}
                  required
                />
              </div>

              <div>
                <label className="text-lg mb-2 block font-semibold">
                  System For
                </label>
                <FormSelectField
                  name="industry_id"
                  disabled={!editForm}
                  required
                >
                  <option
                    className={editForm ? `hidden` : ""}
                    value={selectedUser.industry.id}
                    selected
                  >
                    {editForm ? "" : selectedUser.industry.name}
                  </option>

                  {industry.map((industry) => (
                    <DisplayNameWithID name={industry.name} id={industry.id} />
                  ))}
                </FormSelectField>
              </div>
              <ErrorDisplay>{error}</ErrorDisplay>
              <FormInputField name={"_method"} type="hidden" value={"PUT"} />
            </div>

            <div className="flex flex-row justify-start">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:w-2/4">
                <input
                  onClick={() => {
                    setEditForm(false);
                  }}
                  type="button"
                  value="Cancel"
                  className={
                    editForm
                      ? "red p-3  rounded-lg text-white cursor-pointer"
                      : "hidden"
                  }
                />
                <input
                  onClick={() => setEditForm(true)}
                  type={editForm ? "submit" : "button"}
                  value={editForm ? "Save Changes" : "Edit"}
                  className="grey p-3  rounded-lg text-white cursor-pointer"
                />
              </div>
            </div>
          </form>
          <div className="flex flex-col gap-3">
            <h3 className="text-2xl font-semibold mt-3">Questions</h3>

            <div className="flex flex-row justify-end">
              <button
                onClick={() => addQuestion()}
                className="py-4 px-10 bg-primaryButton  hover:shadow hover:shadow-primaryButton text-white font-thin rounded-xl"
              >
                Add Question
              </button>
            </div>

            <QuestionsTable
              columns={QuestionColumns}
              tableRef={tableRef}
              search={false}
              searchTerm="key"
              customUrl={`${process.env.REACT_APP_BASEURL}/api/system/${selectedUser.id}/edit?`}
            />
          </div>
        </FormProvider>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "enable" ? "deactivate " : "activate "}
          {selectedUser.name}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "enable" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default FeedbackSystems;
