import * as yup from "yup";

export const userSchema = yup.object().shape({
  name: yup.string().required("Name is required"),

  brand_name: yup.string().required("Brand name is required"),
  phone_no: yup
    .string()
    .required("Contact number is required")
    .matches(
      /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
      "Inavlid contact format"
    ),
  industry: yup.string().required("Industry is required"),
});
