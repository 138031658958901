import "./App.css";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Container from "./components/Container/Container";
import RightNavbar from "./components/RightNavbar/RightNavbar";
import toast, { Toaster } from "react-hot-toast";
import Analytics from "./components/Analytics/Analytics";
import Campaings from "./components/Campaigns/Campaings";
import Team from "./components/Team/Team";
import WithoutNav from "./routes/WithoutNav";
import WithNav from "./routes/WithNav";
import NavContext from "./Context/NavContext";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import EmailSent from "./pages/EmailSent";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/SuperAdmin/Dashboard/Dashboard";
import AdDashboad from "./pages/Admin/Dashboard/Dashboard";
import SignUpWait from "./pages/SignUpWait";
import PageNotFound from "./pages/PageNotFound";
import ProtectedRoute from "./components/protected-routes/ProtectedRoute";
import EmailVerify from "./pages/EmailVerify";
import ActiveBrands from "./pages/SuperAdmin/Brands/ActiveBrands";
import InActiveBrands from "./pages/SuperAdmin/Brands/InActiveBrands";
import BrandRequests from "./pages/SuperAdmin/Brands/BrandRequests";
import Industries from "./pages/SuperAdmin/Industries/Industries";
import FeedbackSystems from "./pages/SuperAdmin/Feedback/FeedbackSystems";
import FeedbackQuestions from "./pages/SuperAdmin/Feedback/FeedbackQuestions";
import AddQuestion from "./pages/SuperAdmin/Feedback/AddQuestion";
import UserRequests from "./pages/SuperAdmin/UserRequests/UserRequests";

function App() {
  const [nav, setNav] = useState(true);
  const value = { nav, setNav };

  return (
    <div className="App">
      <NavContext.Provider value={value}>
        <Toaster />
        <Routes>
          <Route element={<WithoutNav />}>
            <Route path="/signup/:id" element={<SignUp />} />
            <Route path="/signup" element={<EmailVerify />} />
            <Route path="/signup-wait" element={<SignUpWait />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/resetpassword/:id" element={<ResetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>

          <Route element={<WithNav />}>
            {/* SUPERADMIN */}
            <Route
              path="/SU/*"
              element={<ProtectedRoute user={"super-admin"} />}
            >
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="industries" element={<Industries />} />
              <Route path="user-req" element={<UserRequests />} />
              <Route path="brands/*">
                <Route index element={<ActiveBrands />} />
                <Route path="active-brands" element={<ActiveBrands />} />
                <Route path="inactive-brands" element={<InActiveBrands />} />
                {/* <Route path="brand-requests" element={<BrandRequests />} /> */}
              </Route>

              <Route path="feedback/*">
                <Route index element={<FeedbackSystems />} />
                <Route path="feedback-systems/*">
                  <Route index element={<FeedbackSystems />} />
                  <Route
                    path="feedback-systems"
                    element={<FeedbackSystems />}
                  />
                  <Route path="add-question" element={<AddQuestion />} />
                </Route>
              </Route>
            </Route>

            {/* ADMIN */}
            <Route path="/AD/*" element={<ProtectedRoute user={"admin"} />}>
              {/* <Route path="dashboard" element={<AdDashboad />} /> */}
            </Route>
          </Route>
        </Routes>
      </NavContext.Provider>
    </div>
  );
}

export default App;
