import Cards from "../../../components/Dashboard/Cards";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";

const Dashboard = () => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [dashData, setDashData] = useState({
    ActiveOffices: "",
    InactiveOffices: "",
    totalOffices: "",
  });

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = (data) => {
    if (!disabled) {
      setDisabled(true);

      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/getTotals`,
        method: "GET",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDashData({
            ActiveOffices: res.data.data.ActiveOffices,
            InactiveOffices: res.data.data.InactiveOffices,
            totalOffices: res.data.data.totalOffices,
          });
          setDisabled(false);
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Data loaded",
        error: "An error has occurred",
      });
    }
  };

  return (
    <main className="content-container">
      <div className="mt-4 flex flex-wrap flex-col  md:flex-row gap-5">
        <Cards
          className="dash-green"
          label={"Total Brands"}
          number={dashData.totalOffices}
        />
        <Cards
          className="dash-blue"
          label={"Premium Brands"}
          number={dashData.ActiveOffices}
        />

        <Cards
          className="dash-red"
          label={"Total Brands"}
          number={dashData.totalOffices}
        />
      </div>
    </main>
  );
};

export default Dashboard;
