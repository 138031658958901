import { useFormContext } from "react-hook-form";

const FormSelectField = ({
  name,
  className,
  label,
  children,
  ...otherProps
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="flex flex-col">
      {label && (
        <label className="text-xl font-bold inline-block mb-2">{label}</label>
      )}
      <select
        className={`${className} input-form`}
        {...register(name)}
        {...otherProps}
      >
        {children}
      </select>
      <div>
        <p className="text-red-700 mt-2 text-left">{errors[name]?.message}</p>
      </div>
    </div>
  );
};

export default FormSelectField;
