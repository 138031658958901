import { useLocation } from "react-router-dom";
import { userSchema } from "../../../components/Validation/SuperAdmin/AddQuestionSchema";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { useState, useEffect } from "react";
import FormInputField from "../../../components/FormInputField";
import FormSelectField from "../../../components/FormSelectField";
import DisplayNameWithID from "../../../components/DisplayNameWithID";
import ErrorDisplay from "../../../components/ErrorDisplay";

const AddQuestion = () => {
  const { state } = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [questionType, setQuestionType] = useState();
  const [error, setError] = useState("");

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  //  fetching industries
  useEffect(() => {
    fetchingQuestionTypes();
  }, []); // <-- empty dependency array

  const fetchingQuestionTypes = () => {
    const promise = axios({
      // Endpoint to send files
      url: `${process.env.REACT_APP_BASEURL}/api/question-type`,
      method: "GET",
      headers: {
        // Add any auth token here
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      // Handle the response from backend here
      .then((res) => {
        console.log(res);
        setQuestionType(res.data.data);
      })

      // Catch errors if any
      .catch((err) => {
        console.log(err);
        return Promise.reject();
      });

    // toast.promise(promise, {
    //   loading: "Loading",
    //   success: "Office Added Successfully",
    //   error: "An error has occurred",
    // });
  };

  const HandleForm = (data) => {
    console.log(data);

    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/question`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);

          setDisabled(false);
          reset();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);

          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Question Added Successfully",
        error: "An error has occurred",
      });
    }
  };

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <h2 className="text-3xl font-bold">Add Question</h2>
        <FormProvider {...methods}>
          <form
            className="flex flex-col gap-3 justify-center align-center whitespace-nowrap mt-4 w-full md:w-2/4"
            onSubmit={handleSubmit(HandleForm)}
          >
            <div>
              <FormInputField name={"name"} label="Name" required />
            </div>

            <div>
              <FormInputField
                name={"screen_title"}
                label="Screen Title"
                required
              />
            </div>

            <div>
              <FormInputField
                name={"description"}
                label="Question Description"
                required
              />
            </div>

            <div>
              <FormSelectField
                name={"question_type_id"}
                label="Question Type"
                required
              >
                <option value="" disabled selected>
                  Select Question Type
                </option>

                {questionType &&
                  questionType.map((questionType) => (
                    <DisplayNameWithID
                      name={questionType.name}
                      id={questionType.id}
                    />
                  ))}
              </FormSelectField>
            </div>

            <div>
              <FormInputField
                name={"feedback_id"}
                type="hidden"
                value={state.id}
                required
              />
            </div>

            <ErrorDisplay>{error}</ErrorDisplay>

            <div className="flex flex-row justify-end">
              <input
                type="submit"
                value="Add"
                className="green p-3 w-1/4 rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </main>
  );
};

export default AddQuestion;
