import { useFormContext } from "react-hook-form";

const InputField = ({ name, className, ...otherProps }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <input
        className={`${className} border h-12 p-7 w-full border-primary text-primary bg-primary bg-opacity-10 focus:bg-white rounded-2xl`}
        {...register(name)}
        {...otherProps}
      />
      <div>
        <p className="text-red-700 mt-2 text-left">{errors[name]?.message}</p>
      </div>
    </div>
  );
};

export default InputField;
