import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import profile from "../images/table/default_profile.svg";
import tableIcons from "../components/MUTable/MaterialTableIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import editIcon from "../images/table/edit.svg";
import deleteIcon from "../images/table/delete.svg";
import reactivateIcon from "../images/table/reactivate.svg";

const Table = ({
  actions,
  columns,
  tableRef,
  customUrl,
  searchTerm,
  search = true,
}) => {
  return (
    <MaterialTable
      tableRef={tableRef}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
        Toolbar: (props) => (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              marginLeft: "-58px",
              marginBottom: "20px",
            }}
          >
            {search && <MTableToolbar {...props} />}
          </div>
        ),
      }}
      icons={tableIcons}
      title=""
      columns={columns}
      options={{
        borderBottom: "2px solid #FFFFFF",
        filtering: true,
        searchFieldStyle: {
          border: "1px solid #CCCCCC ",
          padding: "8px",
          borderRadius: "10px",
        },
        debounceInterval: 700,
        headerStyle: {
          fontWeight: "bold",
          fontSize: "15px",
          textTransform: "uppercase",
        },
        rowStyle: {
          borderColor: "white",
        },
        search: search,
        sorting: false,
        actionsColumnIndex: -1,
      }}
      actions={actions}
      data={(query) =>
        new Promise((resolve, reject) => {
          var myHeaders = new Headers();
          myHeaders.append("Accept", "application/json");
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );
          myHeaders.append("content-type", "application/json");

          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };
          let url = customUrl;

          if (query.filters.length) {
            const filter = query.filters.map((filter) => {
              if (filter.value[0] && filter.value.length == 1)
                return `&${filter.column.field}${filter.operator}${filter.value[0]}`;
            });
            url += filter.join("");
          }

          if (query.search) {
            url += `&${searchTerm}=` + query.search;
          }
          url += "&limit=" + query.pageSize;
          url += "&page=" + (query.page + 1);

          console.log("page", parseInt(query.page));
          fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              console.log(result);
              console.log("apicall", result.data);
              resolve({
                data: result.data,
                page: query.page,
                totalCount: result.total,
              });
            })
            .catch((error) => console.log("error", error));
          // .then((result) => {
          //   console.log("res", result);
          //   resolve({
          //     data: result.data,
          //     page: result.page - 1,
          //     totalCount: result.total,
          //   });
          // });
        })
      }
    />
  );
};

export default Table;
