import { useState } from "react";
import eye from "../images/eye.svg";
import eye_slash from "../images/eye-slash.svg";
import InputField from "./InputField";

const PasswordInputField = ({ className, name, ...otherProps }) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <>
      <div className="relative">
        <img
          className="absolute h-6 w-6 cursor-pointer right-6 top-4"
          src={showPass ? eye_slash : eye}
          onClick={() => {
            setShowPass((prevCheck) => !prevCheck);
          }}
          alt=""
        />
        <InputField name={name} type={showPass ? "text" : "password"} />
      </div>
    </>
  );
};

export default PasswordInputField;
