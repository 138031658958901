const PermModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div className="custom-modal  p-4">
      <div
        className={`modal-content overflow-auto max-h-90% p-4 ${props.className}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default PermModal;
