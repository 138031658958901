import * as yup from "yup";

export const userSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string(),
  password: yup.string(),
  location: yup.string(),
  phone: yup.string(),
  photo: yup.string(),
  files: yup.mixed(),
});
