import * as yup from "yup";
import { format } from "date-fns";
const date = new Date();
export const userSchema = yup.object().shape({
  username: yup.string(),
  email: yup
    .string()
    .required("Email address is missing")
    .email("Please enter a valid email address"),
  password: yup.string(),
  phone: yup
    .string()
    .required("Contact number is missing")
    .matches(
      /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
      "Inavlid contact format"
    ),
  designation: yup.string(),
  JoiningDate: yup.date(),
  role: yup.string(),
  photo: yup.string(),
  leaves: yup.string(),
  supervisedBy: yup.string(),
});
