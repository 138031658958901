import profile from "../../../images/table/default_profile.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import editIcon from "../../../images/table/edit.svg";
import deleteIcon from "../../../images/table/delete.svg";
import reactivateIcon from "../../../images/table/reactivate.svg";
import React, { useState, useRef, useReducer } from "react";
import { userSchema } from "../../../components/Validation/SuperAdmin/AddOfficeSchema";
import { useForm } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import ErrorDisplay from "../../../components/ErrorDisplay";

const UserRequests = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    status: "",
    id: "",
    address: "",
    created_at: "",
    phone_number: "",
    image: "",
  });
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  // const forceUpdate = React.useReducer((bool) => !bool)[1];

  //For form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const approveRequest = () => {
    if (!disabled) {
      setDisabled(true);
      setError();

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/verify-user/${selectedUser.id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        // data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          console.log(err.response.data.data);
          setError(err.response.data.data);
          setDisabled(false);
          setModal(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Welcome email sent",
        error: "An error has occurred",
      });
    }
  };

  const rejectRequest = (data) => {
    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      const data = {
        requestStatus: "Rejected",
        status: "Inactive",
      };

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/${selectedUser._id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          setModal(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User status updated",
        error: "An error has occurred",
      });
    }
  };

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);
    setEditModal(true);
  };

  const HandleForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);

          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setError(err.response.data.data);
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Office status updated",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    console.log(data);
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/${selectedUser._id}`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          setEditModal(false);
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Office Added Successfully",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);
    if (!disabled) {
      setDisabled(true);

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/offices/${selectedUser._id}?requestStatus=Pending`,
        method: `${selectedUser.status === "Active" ? "DELETE" : "POST"}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);

          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "Office Updated Successfully",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const formatDate = (date) => {
    if (date) {
      return date.slice(0, 10);
    } else return null;
  };

  const columns = [
    {
      title: "",
      field: "image",
      filtering: false,
      render: (rowData) => (
        <img
          onError={handleImageErr}
          src={
            !rowData.image
              ? profile
              : `${process.env.REACT_APP_BASEURL}/uploads/profile/${rowData.image}`
          }
          className="h-12 w-12 rounded-full"
        />
      ),
    },
    { title: "NAME", field: "name", filtering: false },
    {
      title: "EMAIL",
      field: "email",
      filtering: false,
      // render: (rowData) => (
      //   <div style={roleStyling(rowData.role)}>{rowData.role}</div>
      // ),
    },
    {
      title: "CREATED DATE",
      field: "created_at",
      filtering: false,
      render: (rowData) => formatDate(rowData.created_at),
    },

    {
      title: "ADDRESS",
      field: "address",
      filtering: false,
    },

    // {
    //   title: "STATUS",
    //   field: "status",
    //   lookup: { Active: "Active", Inactive: "Inactive" },
    //   render: (rowData) => (
    //     <div
    //       style={
    //         rowData.status == "Active"
    //           ? {
    //               background: "#55ac1c47",
    //               border: "1px solid #55AC1C",
    //               borderRadius: "8px",
    //               color: "#55AC1C",
    //               width: "5rem",
    //               padding: "3px",
    //               textAlign: "center",
    //             }
    //           : {
    //               background: "#C3222247",
    //               color: "#C32222",
    //               border: "1px solid #C32222",
    //               borderRadius: "8px",
    //               width: "5rem",
    //               padding: "3px",
    //               textAlign: "center",
    //             }
    //       }
    //     >
    //       {rowData.status}
    //     </div>
    //   ),
    // },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        <Table
          columns={columns}
          tableRef={tableRef}
          searchTerm="key"
          customUrl={`${process.env.REACT_APP_BASEURL}/api/user-req?`}
          actions={[
            {
              icon: ArrowForwardIcon,
              onClick: (event, rowData) => {
                editUser(rowData);
                setError("");
                setEditForm(false);
              },
            },

            // (rowData) => ({
            //   icon: "delete",
            //   tooltip: "Delete User",
            //   onClick: (event, rowData) =>
            //     confirm("You want to delete " + rowData.name),
            //   disabled: rowData.birthYear < 2000,
            // }),
          ]}
        />
      </div>

      {/* Add User Modal */}
      <Modal
        show={modal}
        close={() => {
          setModal(false);
        }}
      >
        <form
          onSubmit={handleSubmit(HandleForm)}
          className="flex flex-col gap-6 justify-center align-center"
        >
          <input
            type="file"
            {...register("file")}
            onChange={(e) => console.log(e.target.files)}
          />

          <div className="grid grid-cols-2 gap-4">
            <input
              className="input-form"
              type="text"
              required
              placeholder="Office Name*"
              {...register("name")}
            />
            <input
              className="input-form"
              type="email"
              autocomplete="new-password"
              required
              placeholder="Office Email*"
              {...register("email")}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <input
              className="input-form"
              type="password"
              autocomplete="new-password"
              required
              placeholder="Password*"
              {...register("password")}
            />
            <input
              className="input-form"
              type="text"
              required
              placeholder="Contact Number*"
              {...register("phone")}
            />
          </div>

          <input
            className="input-form"
            type="text"
            required
            placeholder="Address*"
            {...register("location")}
          />

          {/* <div className="grid grid-cols-2 gap-4">
            <input className="input-form" type="text" />
            <input className="input-form" type="text" name="" id="" />
          </div> */}

          <div className="grid grid-cols-2 gap-4">
            <input
              onClick={() => setModal(false)}
              type="button"
              value="Cancel"
              className="red p-3 rounded-lg text-white cursor-pointer"
            />
            <input
              type="submit"
              value="Save"
              className="grey p-3 rounded-lg text-white cursor-pointer"
            />
          </div>
        </form>
      </Modal>

      {/* Accept Request User Modal */}
      <Modal
        show={editModal}
        close={() => {
          setEditModal(false);
        }}
      >
        <form
          onSubmit={handleSubmit(HandleEditForm)}
          className="flex flex-col gap-6 justify-center align-center"
        >
          <img
            onError={handleImageErr}
            className={editForm ? "hidden" : "h-28 w-28 mx-auto rounded-full"}
            src={
              selectedUser.image === "no-image.jpg"
                ? profile
                : `${process.env.REACT_APP_BASEURL}/uploads/offices/${selectedUser.image}`
            }
            alt=""
          />

          <div className={editForm ? "flex flex-col" : "hidden"}>
            <label className="mb-2 text-lg font-semibold">Image</label>
            <input
              type="file"
              {...register("file")}
              onChange={(e) => console.log(e.target.files)}
            />
          </div>

          {/* <input
            type="file"
            
            onChange={(e) => console.log(e.target.files)}
            {...register("file")}
          /> */}

          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="mb-2 text-lg font-semibold">Name</label>
              <input
                className="input-form"
                type="text"
                disabled={!editForm}
                value={editForm ? null : selectedUser.name}
                // {...register("name")}
              />
            </div>

            <div className="flex flex-col">
              <label className="mb-2 text-lg font-semibold">Email</label>
              <input
                className="input-form"
                disabled={!editForm}
                value={editForm ? null : selectedUser.email}
                // {...register("password")}
              />
            </div>

            {/* <div className="flex flex-col">
              <label className="mb-2 text-lg font-semibold">
                Contact Number
              </label>
              <input
                className="input-form"
                type="text"
                disabled={!editForm}
                value={editForm ? null : selectedUser.phone_number}
                // {...register("phone")}
              />
            </div> */}

            {/* <div className="flex flex-col">
              <label className="mb-2 text-lg font-semibold">Email</label>
              <input
                className="input-form"
                type="email"
                disabled={!editForm}
                value={editForm ? null : selectedUser.email}
                {...register("email")}
              />
            </div> */}
          </div>

          <div className="flex flex-col">
            <label className="mb-2 text-lg font-semibold">Created At</label>
            <input
              className="input-form"
              disabled={!editForm}
              value={editForm ? null : formatDate(selectedUser.created_at)}
              // {...register("password")}
            />
          </div>

          {/* <div className="flex flex-col">
            <label className="mb-2 text-lg font-semibold">Address</label>
            <input
              className="input-form"
              type="text"
              disabled={!editForm}
              value={editForm ? null : selectedUser.address}
              {...register("location")}
            />
          </div> */}

          {/* <div className="grid grid-cols-2 gap-4">
            <input className="input-form" type="text" />
            <input className="input-form" type="text" name="" id="" />
          </div> */}

          <ErrorDisplay>{error}</ErrorDisplay>

          <div className="flex flex-row mt-6 md:justify-center gap-3">
            <button
              onClick={approveRequest}
              className="green-dark text-white rounded-lg h-10 w-32 cursor-pointer"
              type="button"
            >
              Accept
            </button>
            {/* <button
              onClick={rejectRequest}
              className="red text-white rounded-lg h-10 w-32 cursor-pointer"
              type="button"
            >
              Reject
            </button> */}
          </div>
        </form>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "Active" ? "deactivate " : "activate "}
          {selectedUser.name}
        </h2>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "Active" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default UserRequests;
