import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { Routes, Route } from "react-router-dom";

import { Outlet } from "react-router";
import Container from "../components/Container/Container";
import RightNavbar from "../components/RightNavbar/RightNavbar";
import NavContext from "../Context/NavContext";
import { useState } from "react/cjs/react.production.min";
import NavbarSuperVisor from "../components/Navbar/NavbarSuperVisor";
import NavbarAdmin from "../components/Navbar/NavbarAdmin";

export default () => {
  const chooseUser = () => {
    if (localStorage.getItem("role") === "super-admin") {
      return <Navbar />;
    }

    if (localStorage.getItem("role") === "admin") {
      return <NavbarAdmin />;
    }

    if (localStorage.getItem("role") === "supervisor") {
      return <NavbarSuperVisor />;
    }
  };

  return (
    <>
      {chooseUser()}
      <Container stickyNav={<RightNavbar />} content={<Outlet />} />
    </>
  );
};
