const SignInButton = ({ children, className, ...otherProps }) => {
  return (
    <button
      className={`${className} px-4 py-3 bg-primary rounded-full text-white w-4/5 md:w-2/5  self-center text-base primary-hover`}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default SignInButton;
