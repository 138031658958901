import { Paper } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import profile from "../../../images/table/default_profile.svg";
import tableIcons from "../../../components/MUTable/MaterialTableIcons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import editIcon from "../../../images/table/edit.svg";
import deleteIcon from "../../../images/table/delete.svg";
import reactivateIcon from "../../../images/table/reactivate.svg";
import React, { useState, useRef, useReducer, useEffect } from "react";
import { userSchema } from "../../../components/Validation/Admin/AddUserSchema";
import { useForm, FormProvider } from "react-hook-form";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import { formatDate } from "../../../components/Functions";

const ActiveBrands = (props) => {
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [role_check, setRole_check] = useState();
  const [showPass, setShowPass] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [supervisor, setSuperVisor] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    status: "",
    designation: "",
    email: "",
    phone: "",
    JoiningDate: "",
    supervisedBy: {
      username: "",
      _id: "",
    },
    _id: "",
    address: "",
    image: "",
  });
  const [status, setStatus] = useState("Active");
  const [error, setError] = useState("");

  // For refreshing table
  const tableRef = useRef();

  //For rerendering
  const refreshTable = () => {
    tableRef.current.onQueryChange();
  };

  // const forceUpdate = React.useReducer((bool) => !bool)[1];

  //For form
  const methods = useForm({
    resolver: yupResolver(userSchema),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== delete obj[k]);
    return obj;
  };

  const deleteUser = (rowData) => {
    setSelectedUser(rowData);
    setDeleteModal(true);
  };

  const editUser = (rowData) => {
    setSelectedUser(rowData);
    setEditModal(true);
  };

  const roleStyling = (role) => {
    if (role === "admin")
      return {
        borderRadius: "5px",
        background: "#5652a1",
        color: "white",
        width: "6rem",
        padding: "3px",
        textAlign: "center",
      };

    if (role === "supervisor")
      return {
        background: "#7CC1A8",
        borderRadius: "5px",
        color: "white",
        width: "6rem",
        padding: "3px",
        textAlign: "center",
      };

    if (role === "employee")
      return {
        borderRadius: "5px",
        background: "#5293A1",
        color: "white",
        width: "6rem",
        padding: "3px",
        textAlign: "center",
      };
  };

  //   useEffect(() => {
  //     fetchingSupervisors();
  //   }, []); // <-- empty dependency array

  //   const fetchingSupervisors = () => {
  //     const promise = axios({
  //       // Endpoint to send files
  //       url: `${process.env.REACT_APP_BASEURL}/api/v1/users?role=supervisor&status=Active`,
  //       method: "GET",
  //       headers: {
  //         // Add any auth token here
  //         // "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //       // Handle the response from backend here
  //       .then((res) => {
  //         console.log(res);
  //         setDisabled(false);

  //         setSuperVisor(res.data.data);
  //       })

  //       // Catch errors if any
  //       .catch((err) => {
  //         setDisabled(false);
  //         return Promise.reject();
  //       });

  //     // toast.promise(promise, {
  //     //   loading: "Loading",
  //     //   success: "Brand Added Successfully",
  //     //   error: "An error has occurred",
  //     // });
  //   };

  //Adding user
  const HandleForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    console.log(data);
    if (!disabled) {
      setDisabled(true);
      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/users`,
        method: "POST",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          console.log(err);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Added Successfully",
        error: "An error has occurred",
      });
    }
  };

  const HandleEditForm = (data) => {
    const file = data["file"];
    data["file"] = file[0];

    console.log(data);
    if (!disabled) {
      setDisabled(true);

      setError();

      //removing empty strings
      const newData = removeEmpty(data);
      console.log(newData);
      let formData = new FormData();

      // appending data into formdata
      Object.keys(newData).forEach((key) => formData.append(key, newData[key]));

      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/v1/users/${selectedUser._id}`,
        method: "PUT",
        headers: {
          // Add any auth token here
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        // Attaching the form data
        data: formData,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated Successfully",
        error: "An error has occurred",
      });
    }
  };

  const HandleDeleteForm = () => {
    setDeleteModal(false);

    if (!disabled) {
      setDisabled(true);
      const data = {
        status: "0",
      };
      const promise = axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASEURL}/api/brand-status/${selectedUser.id}`,
        method: `PUT`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: data,
      })
        // Handle the response from backend here
        .then((res) => {
          console.log(res);
          setDisabled(false);
          setEditModal(false);
          reset();
          refreshTable();
        })

        // Catch errors if any
        .catch((err) => {
          setDisabled(false);
          return Promise.reject();
        });

      toast.promise(promise, {
        loading: "Loading",
        success: "User Updated Successfully",
        error: "An error has occurred",
      });
    }
  };

  const handleImageErr = (err) => {
    err.target.src = profile;
  };

  const columns = [
    {
      title: "",
      field: "image",
      filtering: false,
      render: (rowData) => (
        <img
          onError={handleImageErr}
          src={
            !rowData.image
              ? profile
              : `${process.env.REACT_APP_BASEURL}/uploads/profile/${rowData.image}`
          }
          className="h-12 w-12 rounded-full"
        />
      ),
    },
    { title: "NAME", field: "name", filtering: false },
    {
      title: "INDUSTRY",
      field: "industry_id",
      filtering: false,
      // render: (rowData) => (
      //   <div style={roleStyling(rowData.role)}>{rowData.role}</div>
      // ),
    },
    {
      title: "CREATED DATE",
      field: "created_at",
      filtering: false,
      render: (rowData) => formatDate(rowData.created_at),
    },

    {
      title: "ADDRESS",
      field: "address",
      filtering: false,
    },

    // {
    //   title: "STATUS",
    //   field: "status",
    //   lookup: { Active: "Active", Inactive: "Inactive" },
    //   render: (rowData) => (
    //     <div
    //       style={
    //         rowData.status == "Active"
    //           ? {
    //               background: "#55ac1c47",
    //               border: "1px solid #55AC1C",
    //               borderRadius: "8px",
    //               color: "#55AC1C",
    //               width: "5rem",
    //               padding: "3px",
    //               textAlign: "center",
    //             }
    //           : {
    //               background: "#C3222247",
    //               color: "#C32222",
    //               border: "1px solid #C32222",
    //               borderRadius: "8px",
    //               width: "5rem",
    //               padding: "3px",
    //               textAlign: "center",
    //             }
    //       }
    //     >
    //       {rowData.status}
    //     </div>
    //   ),
    // },
  ];

  return (
    <main className="content-container">
      <div className="py-7 px-8 relative bg-white shadow-2xl rounded-2xl">
        {/* <div className="flex flex-col  gap-6 mb-4 md:absolute z-10 right-10">
          <button
            onClick={() => setModal(true)}
            className="py-4 px-10 green text-white font-thin rounded-xl"
          >
            Add User
          </button>
        </div> */}
        <Table
          columns={columns}
          tableRef={tableRef}
          searchTerm="key"
          customUrl={`${process.env.REACT_APP_BASEURL}/api/brand?status=active`}
          actions={[
            // {
            //   icon: EditIcon,
            //   onClick: (event, rowData) => {
            //     reset();
            //     editUser(rowData);
            //     setEditForm(false);
            //   },
            // },
            (rowData) => ({
              icon: rowData.status === "1" ? DeleteIcon : RotateLeftIcon,

              onClick: (event, rowData) => {
                reset();
                deleteUser(rowData);
              },
            }),
          ]}
        />
      </div>

      {/* Add Brand Modal */}
      <Modal
        show={modal}
        close={() => {
          setModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleForm)}
            className="flex flex-col gap-6 justify-center align-center whitespace-nowrap"
          >
            <input
              type="file"
              {...register("file")}
              onChange={(e) => console.log(e.target.files)}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <input
                  className="input-form"
                  type="text"
                  placeholder="Name*"
                  {...register("username")}
                />
                <p className="text-red-700 mt-2">
                  {errors.username?.message && errors.username?.message}
                </p>
              </div>
              <div>
                <input
                  className="input-form"
                  type="email"
                  autocomplete="new-password"
                  placeholder="Email*"
                  {...register("email")}
                />
                <p className="text-red-700 mt-2">
                  {errors.email?.message && errors.email?.message}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <input
                  className="input-form"
                  type="text"
                  required
                  placeholder="Designation*"
                  {...register("designation")}
                />
              </div>

              <div>
                <select
                  name="role"
                  onClick={(e) => {
                    setRole_check(e.target.value);
                    console.log(role_check);
                  }}
                  className="input-form"
                  required
                  {...register("role")}
                >
                  <option value="" disabled selected>
                    Select Role
                  </option>
                  <option value="employee">Employee</option>
                  <option value="admin">Admin</option>
                  <option value="supervisor">Supervisor</option>
                </select>
              </div>
            </div>

            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <select
                name="supervisedBy"
                className="input-form"
                disabled={
                  role_check === "employee" || "supervisor" ? false : true
                }
                id="supervisedBy"
                {...register("supervisedBy")}
              >
                <option value="" disabled selected>
                  Select Supervisor
                </option>
                <option value="">None</option>
                {role_check == "employee" || "supervisor"
                  ? supervisor.map((supervisor) => (
                      <Display_Supervisor
                        name={supervisor.username}
                        _id={supervisor._id}
                      />
                    ))
                  : null}
              </select>
            </div>

            <div>
              <input
                className="input-form"
                type="text"
                required
                placeholder="Contact Number*"
                {...register("phone")}
              />
              <p className="text-red-700 mt-2">
                {errors.phone?.message && errors.phone?.message}
              </p>
            </div>
          </div> */}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <input
                  className="input-form"
                  type="password"
                  autocomplete="new-password"
                  required
                  placeholder="Password*"
                  {...register("password")}
                />
              </div>

              <div>
                <input
                  className="input-form"
                  required
                  placeholder="JoiningDate"
                  type="date"
                  id="JoiningDate"
                  name="JoiningDate"
                  size="30"
                  {...register("JoiningDate")}
                ></input>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                type="submit"
                value="Add"
                className="grey p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Edit Brand Modal */}
      <Modal
        show={editModal}
        close={() => {
          setEditModal(false);
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(HandleEditForm)}
            className="flex flex-col gap-6 justify-center align-center whitespace-nowrap"
          >
            {/* <FormImageField
              name={"image"}
              edit={editForm}
              value={selectedUser.image}
            /> */}
            {/* <img
              onError={handleImageErr}
              className={editForm ? "hidden" : "h-28 w-28 mx-auto rounded-full"}
              src={
                selectedUser.image === ""
                  ? profile
                  : `${process.env.REACT_APP_BASEURL}/uploads/profile/${selectedUser.photo}`
              }
              alt=""
            /> */}

            {/* <div className={editForm ? "flex flex-col" : "hidden"}>
              <label className="mb-2 text-lg font-semibold">Image</label>
              <input
                type="file"
                {...register("file")}
                onChange={(e) => console.log(e.target.files)}
              />
            </div> */}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="mb-2 text-lg font-semibold">Name</label>
                <input
                  className="input-form"
                  type="text"
                  disabled={!editForm}
                  value={editForm ? null : selectedUser.username}
                  placeholder="Name*"
                  {...register("username")}
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-2 text-lg font-semibold">Email</label>
                <input
                  className="input-form"
                  type="email"
                  disabled={!editForm}
                  value={editForm ? null : selectedUser.email}
                  autocomplete="new-password"
                  placeholder="Email*"
                  {...register("email")}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="mb-2 text-lg font-semibold">
                  Designation
                </label>
                <input
                  className="input-form"
                  type="text"
                  disabled={!editForm}
                  value={editForm ? null : selectedUser.designation}
                  placeholder="Designation*"
                  {...register("designation")}
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-2 text-lg font-semibold">Role</label>
                <select
                  disabled={!editForm}
                  name="role"
                  onClick={(e) => {
                    setRole_check(e.target.value);
                    console.log(role_check);
                  }}
                  className="input-form"
                  {...register("role")}
                >
                  <option
                    disabled={!editForm}
                    value={editForm ? null : selectedUser.role}
                    selected
                  >
                    {editForm ? null : selectedUser.role}
                  </option>
                  <option value="employee">Employee</option>
                  <option value="admin">Admin</option>
                  <option value="supervisor">Supervisor</option>
                </select>
              </div>
            </div>

            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="mb-2 text-lg font-semibold">Supervisor</label>
              <select
                name="supervisedBy"
                className="input-form"
                disabled={!editForm}
                id="supervisedBy"
                {...register("supervisedBy")}
              >
                <option
                  value={
                    selectedUser.supervisedBy
                      ? selectedUser.supervisedBy.username
                      : ""
                  }
                  disabled={!editForm}
                  selected
                >
                  {selectedUser.supervisedBy
                    ? selectedUser.supervisedBy.username
                    : ""}
                </option>
                <option value="">None</option>
                {role_check == "employee" || "supervisor"
                  ? supervisor.map((supervisor) => (
                      <Display_Supervisor
                        name={supervisor.username}
                        _id={supervisor._id}
                      />
                    ))
                  : null}
              </select>
            </div>

            <div className="flex flex-col">
              <label className="mb-2 text-lg font-semibold">
                Contact Number
              </label>
              <input
                className="input-form"
                type="text"
                disabled={!editForm}
                value={editForm ? null : selectedUser.phone}
                placeholder="Contact Number*"
                {...register("phone")}
              />
            </div>
          </div> */}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="mb-2 text-lg font-semibold">Password</label>
                <input
                  className="input-form"
                  type="password"
                  disabled={!editForm}
                  autocomplete="new-password"
                  placeholder="Password*"
                  {...register("password")}
                />
              </div>

              {/* <div className="flex flex-col">
              <label className="mb-2 text-lg font-semibold">Joining Date</label>
              <input
                className="input-form"
                placeholder="JoiningDate"
                type="date"
                id="JoiningDate"
                name="JoiningDate"
                size="30"
                {...register("JoiningDate")}
              ></input>
            </div> */}
            </div>

            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input className="input-form" type="text" />
            <input className="input-form" type="text" name="" id="" />
          </div> */}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                onClick={() => setEditModal(false)}
                type="button"
                value="Cancel"
                className="red p-3  rounded-lg text-white cursor-pointer"
              />
              <input
                onClick={() => setEditForm(!editForm)}
                type={editForm ? "button" : "submit"}
                value={editForm ? "Save Changes" : "Edit"}
                className="grey p-3  rounded-lg text-white cursor-pointer"
              />
            </div>
          </form>
        </FormProvider>
      </Modal>

      {/* Delete User Modal */}
      <Modal
        show={deleteModal}
        close={() => {
          setDeleteModal(false);
        }}
      >
        <h2 className="text-lg">
          Are you sure you want to <br />
          {selectedUser.status == "1" ? "deactivate " : "activate "}
          {selectedUser.name}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
          <input
            onClick={() => setDeleteModal(false)}
            type="button"
            value="Cancel"
            className="grey p-3 rounded-lg text-white cursor-pointer"
          />
          <input
            type="button"
            onClick={() => HandleDeleteForm()}
            value={selectedUser.status == "1" ? "Deactivate" : "Activate"}
            className="red p-3 rounded-lg text-white cursor-pointer"
          />
        </div>
      </Modal>
    </main>
  );
};

export default ActiveBrands;
